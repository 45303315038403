article#nfl {    
    padding-top: 4em;
}

table.nfl {
    background-color: whitesmoke;
    color: black;    
    margin-top: 1em;
}

table.nfl th,table.nfl td {
    text-align: center !important;
}

table.afc th {
    background-color: midnightblue;
    
}

table.nfc th {
    background-color: darkred;
}

.teamImageRow img, .teamImageRow.teamname {
    vertical-align: middle;    
}

.teamname {
    font-size: x-small;
    font-weight: bold;
    color: black;
    margin-left: 0.5em;
}

.tabs {
    background: '#fff';
}

.slide {
    padding: 15;
    min-height: 100;
    color: '#fff';
}

.slide1 {
    background-color: '#FEA900';
}

.slide2 {
    background-color: '#B3DC4A';
}

.slide3 {
    background-color: '#6AC0FF';
}

.MuiButtonBase-root[tabindex="0"] {
    background-color: #f56a6a;
}

.MuiButtonBase-root[tabindex="0"] > span.MuiTab-wrapper {    
    color: white;
}

.nflPlayer {
    position: absolute;
    bottom: 0px;
    left: -20em;
    height: 100%;
    animation: henryRunning 10s;
}

@keyframes henryRunning {
    0% {
        left: -10%;
    }
    50% {
        left: 50%;
    }
    100% {
        left: 100%;
    }
}

.nflTitle {
    animation: textFadeIn 5s;
}

@keyframes textFadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}

#parent {
    width: 100%;
    position: relative;
}

#container {
    width: 100%;
    position: absolute;
    bottom: 0px;
}

table.nflstats th {
    color: black;
    font-size: x-large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    vertical-align: text-bottom;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 0px;
}

table.nflstats td {
    color: whitesmoke;
    font-size: x-large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    padding: 0px;
}

table.nflstats tr {
    opacity: 0.7;
}

table.nflstats tr.arizonacardinals {
    background-image: linear-gradient(to bottom, darkred, whitesmoke);
}

table.nflstats tr.atlantafalcons {
    background-image: linear-gradient(to bottom, black, darkred);
}

table.nflstats tr.baltimoreravens {
    background-image: linear-gradient(to bottom, indigo, whitesmoke);
}

table.nflstats tr.buffalobills {
    background-image: linear-gradient(to bottom, whitesmoke, darkblue);
}

table.nflstats tr.carolinapanthers {
    background-image: linear-gradient(to bottom, whitesmoke, steelblue);
}

table.nflstats tr.chicagobears {
    background-image: linear-gradient(to bottom, midnightblue, orange);
}

table.nflstats tr.cincinnatibengals {
    background-image: linear-gradient(to bottom, orangered, black);
}

table.nflstats tr.clevelandbrowns {
    background-image: linear-gradient(to bottom, orangered, black);
}

table.nflstats tr.dallascowboys {
    background-image: linear-gradient(to bottom, whitesmoke, gray);
}

table.nflstats tr.denverbroncos {
    background-image: linear-gradient(to bottom, orangered, midnightblue);
}

table.nflstats tr.detroitlions {
    background-image: linear-gradient(to bottom, gray, lightblue);
}

table.nflstats tr.greenbaypackers {
    background-image: linear-gradient(to bottom, darkgreen, goldenrod);
}

table.nflstats tr.houstontexans {
    background-image: linear-gradient(to bottom, midnightblue, darkred);
}

table.nflstats tr.indianapoliscolts {
    background-image: linear-gradient(to bottom, whitesmoke, midnightblue);
}

table.nflstats tr.jacksonvillejaguars {
    background-image: linear-gradient(to bottom, lightblue, black);
}

table.nflstats tr.kansascitychiefs {
    background-image: linear-gradient(to bottom, darkred, whitesmoke);
}

table.nflstats tr.lasvegasraiders {
    background-image: linear-gradient(to bottom, black, whitesmoke);
}

table.nflstats tr.losangeleschargers {
    background-image: linear-gradient(to bottom, lightblue, goldenrod);
}

table.nflstats tr.losangelesrams {
    background-image: linear-gradient(to bottom, midnightblue, goldenrod);
}

table.nflstats tr.miamidolphins {
    background-image: linear-gradient(to bottom, aqua, goldenrod);
}

table.nflstats tr.minnesotavikings {
    background-image: linear-gradient(to bottom, purple, goldenrod);
}

table.nflstats tr.newenglandpatriots {
    background-image: linear-gradient(to bottom, gray, midnightblue);
}

table.nflstats tr.neworleanssaints {
    background-image: linear-gradient(to bottom, black, white);
}

table.nflstats tr.newyorkgiants {
    background-image: linear-gradient(to bottom, midnightblue, whitesmoke);    
}

table.nflstats tr.newyorkjets {
    background-image: linear-gradient(to bottom, darkgreen, whitesmoke);    
}

table.nflstats tr.philadelphiaeagles {
    background-image: linear-gradient(to bottom, whitesmoke, darkgreen);    
}

table.nflstats tr.pittsburghsteelers {
    background-image: linear-gradient(to bottom, black, goldenrod);    
}

table.nflstats tr.sanfrancisco49ers {
    background-image: linear-gradient(to bottom, darkred, goldenrod);
}

table.nflstats tr.seattleseahawks {
    background-image: linear-gradient(to bottom, darkgreen, midnightblue);
}

table.nflstats tr.tampabaybuccaneers {
    background-image: linear-gradient(to bottom, whitesmoke, darkred);
}

table.nflstats tr.tennesseetitans {
    background-image: linear-gradient(to bottom, darkblue, gray);
}

table.nflstats tr.washingtonfootballteam {
    background-image: linear-gradient(to bottom, maroon, goldenrod);
}

table.nflstats img {
    vertical-align: middle;
}

table.gameweeks thead tr {
    overflow-x: scroll;
}

table.gameweeks {
    border-collapse: unset;
    border-spacing: unset;
    margin-top: 1em;
}

table.gameweeks thead tr th.gameweek {
    border: solid 2px #f56a6a;
    padding: 10px 11px;    
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-left: 1em;
}

table.gameweeks thead tr th a {
    color:#f56a6a;
    border-bottom: none;
}

table.gameweeks thead tr th.active button {
    background-color:#f56a6a;
    color: whitesmoke !important;
}

table.gameweeks thead tr th button.active {
    background-color:#f56a6a;
    color: whitesmoke !important;
}

.versus {
    text-align: center;
}

.score {
    font-size: large;
    text-align: center;
}

.accuracy {
    font-size: large;
    text-align: right;
}

.accuracyPercentage {
    font-size: x-large;
    text-align: center;
}

.predictionLeft {
    border: 1px dotted rgba(255, 0, 0, .2);
    border-right: none;
}

.predictionRight {
    border: 1px dotted rgba(255, 0, 0, .2);
    border-left: none;
}

.submitDataRow {
    margin-top: 1em;
}

.submitData {
    color: whitesmoke !important;
    background-color: navy !important;
    box-shadow: unset;
}

.gameweeks td.score {
    width: 50px;    
}