article#accumulators {
    position: absolute;
    display: block;
    padding-top: 4em;
  }
  
  .header {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      color: white;
      background-color: black;
      padding: 1%;
      text-align: center;
    }
  
    article#accumulators {
      display: block;
      padding-top: 4em;    
    }
  
    .showAll {
      font-size:x-small;
      text-align:right;
    }
  
    .key {
      font-size:small;
      text-align:right;
      padding-bottom: 1em;
    }
  
    .rowDiv {
      color:black;
      padding-top:0px;
    }
  
    #flip {
      vertical-align:middle;
    }
  
    .panelDiv {
      background-color:midnightblue;
    }
  
    .panelSpan {
      color:white;
      text-transform:uppercase;
    }
  
    .panel-header {
      font-size:small;
      background-color: darkred;
      border-radius: 5px;
      border: solid 1px black;    
    }
  
  .panel-toggle {
    height: 2em;
    line-height: unset;
    padding: 0px;  
    color: #fff !important;
    border-radius: 5px;
    box-shadow: unset;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
  }
  
  .selections {
      padding-top: 0px;
      padding: 5px;
      text-align: center;
      background-color: lightgray;
      border: solid 1px black;
      color: black;
      font-size: small;    
    }
  
    .expand {
      border-radius: 5px;
      float:right;padding-top:2px;padding-bottom:2px;
      margin-right: 1em;
    }
    
    table.incorrectSelections {
      background-color: #191970;
      color: #fff;
      font-size: smaller;
      border-collapse: collapse;
      border: 1px solid gray;
    }
  
    div.table-wrapper table thead {
      background-color: black;    
    }
    
    div.table-wrapper table thead th {
      text-align: left;    
      color: whitesmoke !important;
      padding: 0.75em 0.75em;
    }
    
  table.incorrectSelections td {    
    white-space: nowrap;
  }
  
  table.incorrectSelections td.points {
    text-align: center;
  }
  
  .winning {
    background-color: darkgreen;
  }
  
  .accordion:hover {
    background-color: firebrick;
  }
  
  button.accordion span {
    text-transform:lowercase;
  }
  
  button.accordion svg {
    height: 50px;
    width: 50px;
    margin-right: 1em;
  }
  
  .panel-toggle span {
    vertical-align: middle;  
  }