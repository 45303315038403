article#predictions {
    position: absolute;
    display: block;
    padding-top: 4em;
  }

table.predictions {
    background-color: #191970;
    color: #fff;
    font-size: smaller;
    border-collapse: collapse;
    border: 1px solid gray;
}

table.predictions th {
    background-color: #1e90ff;    
    color: black;
    padding: 0.0em 0.75em 0em 0.75em;
}

table.predictions th.center {
    text-align: center;
}

table.predictions td {
    border: 1px solid #f5f5f5!important;
}

table.predictions td.team {
    padding-right: 52px;
}

table.predictions td.points {
    text-align: center;
}

table.predictions td.positions {
    text-align: center;
}

img.player {
    height: 69px;
    width: 50px;
}

#chart {
    overflow-x: auto;
}

rect.predictions {
    stroke: #fff;
    fill: #4682b4;
}

text.predictions {
    font: 13px sans-serif;
    fill: red;
}

rect.predictions {
    font: 10px sans-serif;
    color: #000;
    stroke: none;
    fill: #0f71b7;
}

svg.predictions {
    width: 450px;
    height: 300px;
}

@keyframes textfadein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}    

svg.predictions text {
    animation: textfadein 2s;
}

p.predictions {
    line-height: 25px;
}

div.lastUpdated {
    font-size: small;
    float: right;
}

#__react_svg_text_measurement_id {
    display: none;
}