table.football-standings {
    width: 35em;
    font-size: x-small;
}

table.football-standings tr.automatic-promotion {
    background-color: midnightblue !important;
    color: whitesmoke !important;
}

table.football-standings tr.playoffs {
    background-color: lightsteelblue !important;
    color: black !important;
}

table.football-standings tr.relegation {
    background-color: darkred !important;
    color: whitesmoke !important;
}

h6 {
    margin-top: 1em;
}

h6 span {    
    background-color: blue;
    color: whitesmoke;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-radius: 25px;    
}
